jQuery( document ).ready( function(){
    var page = 2;
    jQuery( function($) {
        jQuery( 'body' ).on( 'click', '.loadmore', function() {
            var data = {
                'action': 'load_posts_by_ajax',
                'page': page,
                'security': blog.security,
                'taxo' : jQuery(this).data('taxo'),
            };
            jQuery.post( blog.ajaxurl, data, function( response ) {
                if( $.trim(response) != '' ) {
                    var $response = $(response);
                    var $listItems = $response.find('li');
                    //console.log($listItems.length);
                    jQuery( '.blog-posts' ).append( $response );
                    page++;
                    if ($listItems.length % 3 !== 0) {
                        jQuery( '.loadmore' ).hide();
                        jQuery( ".no-more-post" ).html( "Pas d'articles supplémentaires" );
                        jQuery( ".no-more-post" ).addClass("btn-main c-black loadmore");
                    }
                };
            });
        });
    });
});